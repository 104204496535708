import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function stravaReducer(state = initialState.strava, action) {
  switch (action.type) {
    case types.LOAD_STRAVA_TRAININGS_SUCCESS:
      return {
        ...state,
        data: action.trainings,
      };
    default:
      return state;
  }
}
