import moment from "moment";

export default {
  trainings: [],
  dictionaries: {
    activityTypes: [],
  },
  authentication: {
    username: null,
    isAuthenticated: false,
    error: null,
    firstName: null,
    lastName: null,
    email: null,
    isAdmin: false,
  },
  apiCallsInProgres: 0,
  user: {
    userProfile: {},
    loggedUserInformation: null,
    userDetails: {
      userId: null,
      age: null,
      height: null,
      weight: null,
      bmi: null,
      additionalInformation: "",
    },
    userTrainingInformation: {},
    userPersonalBest: {},
  },
  coachUsers: [],
  selectedUser: {},
  exercisePlans: {
    data: [],
    selectedPlans: [],
    exercises: [],
    selectedExercisePlanId: null,
  },
  navigation: {
    trainingForm: {
      selectedTab: "general",
    },
  },
  core: {
    selectedDate: moment().format("YYYY-MM-DD"),
  },
  forum: {
    categories: [],
    ui: {
      mainView: true,
    },
    threads: [],
    allThreads: [],
    threadAnswers: [],
  },
  config: {
    connectedWithStrava: false,
  },
  strava: {
    data: null,
    isLoading: false,
    error: null,
  },
};
