import { useSelector } from "react-redux";
import UserConfigurationForm from "./UserConfigurationForm";
import { useEffect } from "react";

function UserConfigurationContainer() {
  const userInfo =
    useSelector((store) => store.auth.userId) ||
    parseInt(localStorage.getItem("userId"));
  useEffect(() => {
    if (userInfo) {
      localStorage.setItem("userId", userInfo);
    }
  }, [userInfo]);
  return (
    <>
      <UserConfigurationForm userId={userInfo} />
    </>
  );
}

export default UserConfigurationContainer;
