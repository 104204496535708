/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import UserCalendar from "components/UserCalendar/UserCalendar.js";
import UserProfileContainer from "components/UserProfile/UserProfileContainer.js";
import ForumContainer from "components/Forum/ForumContainer";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import HomePage from "components/home/HomePage";
import { isKosekTeam } from "utils/locationHelper";

const dashboardRoutes = [
  {
    path: isKosekTeam() ? "/kosekteam" : "/kreskateam" || "/",
    name: "Pulpit",
    icon: "nc-icon nc-chart-pie-35",
    component: HomePage,
    layout: "/admin",
  },
  {
    path: isKosekTeam()
      ? "/kosekteam/training-plan"
      : "/kreskateam/training-plan",
    name: "Plan treningowy",
    icon: "nc-icon nc-bullet-list-67",
    component: UserCalendar,
    layout: "/admin",
  },
  {
    path: isKosekTeam() ? "/kosekteam/user" : "/kreskateam/user",
    name: "Profil użytkownika",
    icon: "nc-icon nc-circle-09",
    component: UserProfileContainer,
    layout: "/admin",
  },
  {
    path: isKosekTeam()
      ? "/kosekteam/training-information"
      : "/kreskateam/training-information",
    name: "Informacje treningowe",
    icon: "nc-icon nc-notes",
    component: UserProfileContainer,
    layout: "/admin",
  },
  {
    path: isKosekTeam()
      ? "/kosekteam/personal-best"
      : "/kreskateam/personal-best",
    name: "Rekordy życiowe",
    icon: "nc-icon nc-notification-70",
    component: UserProfileContainer,
    layout: "/admin",
  },
  {
    path: isKosekTeam() ? "/kosekteam/forum" : "/kreskateam/forum",
    name: "Forum",
    icon: "nc-icon nc-chat-round",
    component: ForumContainer,
    layout: "/admin",
  },
  {
    path: isKosekTeam() ? "/kosekteam/config" : "/kreskateam/config",
    name: "Konfiguracja",
    icon: "nc-icon nc-settings-gear-64",
    component: ForumContainer,
    layout: "/admin",
  },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Powiadomienia",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
