import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col, Container } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import ImageUploader from "quill-image-uploader";
import ImageCompress from "quill-image-compress";

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageCompress", ImageCompress);

function ForumForm({
  onCancel,
  categories,
  onAddForumThread,
  form,
  onSetForm,
  onSetSelectedCategoryId,
}) {
  const [errors, setErrors] = useState({});
  const [description, setDescription] = useState("");
  const reactQuillRef = useRef(null);

  const onChange = (event) => {
    const { name, value } = event.target;

    if (name === "category") {
      onSetSelectedCategoryId(value);
    }

    onSetForm((prevState) => ({
      ...prevState,
      [name]: name === "categoryId" ? Number(value) : value,
    }));
  };

  const onSave = (e) => {
    e.preventDefault();
    onAddForumThread(form);
    onCancel();
  };

  const modules = {
    toolbar: {
      container: [
        [{ size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 600, // default
      maxHeight: 800, // default
      imageType: "*", // default
      debug: true, // default
      suppressErrorLogging: false, // default
      handleOnPaste: true, //default
      insertIntoEditor: true, // default
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  return (
    <Container fluid>
      <Form onSubmit={onSave}>
        <Row>
          <Col className="pr-1" md="12">
            <Form.Group className="mb-3" controlId="training">
              <Form.Label>Temat wątku</Form.Label>
              <Alert show={"onSave" in errors} variant="danger">
                {errors.onSave}
              </Alert>
              <Form.Control
                type="text"
                required
                placeholder="Wprowadź tytuł wątku"
                name="title"
                onChange={onChange}
                // disabled={disableField()}
                value={form?.title}
              />
              {/* <Form.Control.Feedback type="invalid">
                To pole jest wymagane!
              </Form.Control.Feedback> */}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="pr-1" md="12">
            <Form.Group className="mb-3" controlId="categoryId">
              <Form.Label>Kategoria</Form.Label>
              <select
                name="categoryId"
                id="categoryId"
                className="custom-dropdown"
                onChange={onChange}
                required
                value={form?.categoryId}
              >
                <option value="" selected disabled>
                  Wybierz kategorię
                </option>
                {categories?.map((category) => (
                  <option
                    value={category.id}
                    key={category.id}
                    selected={category.id === form?.categoryId}
                  >
                    {category?.name}
                  </option>
                ))}
              </select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="pr-1" md="12">
            <Form.Group className="mb-3" controlId="trainingDescription">
              <Form.Label>Treść wątku</Form.Label>
              <ReactQuill
                theme="snow"
                ref={reactQuillRef}
                required
                value={form?.description}
                onChange={(e) =>
                  onChange({ target: { name: "description", value: e } })
                }
                modules={modules}
                formats={formats}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="pr-1" md="12">
            <Form.Group
              className="mb-3 buttons mgt-12"
              style={{ gridTemplateColumns: "1fr 1fr" }}
              controlId="actions"
            >
              <Button variant="dark" type="button" onClick={onCancel}>
                Anuluj
              </Button>
              <Button
                variant="primary"
                type="submit"
                // disabled={saving}
              >
                Zapisz
                {/* {saving ? "Zapisuje..." : "Zapisz"} */}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default ForumForm;
