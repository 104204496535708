import StravaConnector from "./StravaConnector";
import React from "react";
import "./configStyles.css";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";

function UserConfigurationForm({ userId }) {
  return (
    <Container fluid className="config-form">
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                <span className="config-title">Synchronizacja ze Strava</span>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="form-group">
                      <StravaConnector userId={userId} />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UserConfigurationForm;
