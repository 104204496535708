import * as types from "./actionTypes";
import * as stravaAPI from "../../api/stravaAPI";
import { beginApiCall, apiCallError } from "./apiStatusActions";

function getStravaTokenSuccess() {
  return { type: types.GET_STRAVA_TOKEN_SUCCESS };
}

function saveStravaTokenSuccess() {
  return { type: types.SAVE_STRAVA_TOKEN_SUCCESS };
}

function setStravaConnectionStatus(isConnected) {
  return { type: types.SET_STRAVA_CONNECTION_STATUS, isConnected };
}

function loadStravaTrainingsSuccess(trainings) {
  return { type: types.LOAD_STRAVA_TRAININGS_SUCCESS, trainings };
}

function saveStravaRefreshTokenToDb(data) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return stravaAPI
      .saveRefreshToken(data)
      .then(() => dispatch(saveStravaTokenSuccess()))
      .catch((error) => dispatch(apiCallError(error)));
  };
}

function preparePayload(data) {
  const objectToSend = {
    id: data.athlete.id,
    userId: data.userId,
    refreshToken: data.refresh_token,
    accessToken: data.access_token,
  };

  return objectToSend;
}

export function getStravaToken(formData, userId) {
  console.log(userId);
  return function (dispatch) {
    dispatch(beginApiCall());
    return stravaAPI
      .getToken(formData)
      .then((response) => {
        dispatch(getStravaTokenSuccess());
        dispatch(
          saveStravaRefreshTokenToDb(
            preparePayload({ ...response, userId: userId })
          )
        );
      })
      .then(() => dispatch(setStravaConnectionStatus(true)))
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

export function verifyStravaConnection(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return stravaAPI
      .checkIfUserIsConnectedWithStrava(userId)
      .then((isConnected) => {
        dispatch(setStravaConnectionStatus(isConnected));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function disconnectUserWithStrava(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return stravaAPI
      .disconnectWithStrava(userId)
      .then(() => {
        dispatch(setStravaConnectionStatus(false));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadStravaTrainings(userId, date) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return stravaAPI
      .getStravaTraining(userId, date)
      .then((trainings) => {
        dispatch(loadStravaTrainingsSuccess(trainings));
      })
      .catch((error) => {
        // dispatch(apiCallError(error));
        throw error;
      });
  };
}
