import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getStravaToken } from "redux/actions/stravaActions";
import "./configStyles.css";
import { verifyStravaConnection } from "redux/actions/stravaActions";
import { disconnectUserWithStrava } from "redux/actions/stravaActions";

function StravaConnector({ userId }) {
  //   const [code, setCode] = useState('');
  const location = useLocation();
  const disptach = useDispatch();

  const isConnected = useSelector((store) => store.config?.connectedWithStrava);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      const urlEncodedData = new URLSearchParams({
        client_id: "130918",
        client_secret: "f737bf78761871e490fb0610836968068df33811",
        code: code,
      });
      disptach(getStravaToken(urlEncodedData, userId));
    }
  }, [location]);

  useEffect(() => {
    disptach(verifyStravaConnection(userId));
  }, [isConnected]);

  function toggleStravaConnection() {
    isConnected
      ? disptach(disconnectUserWithStrava(userId))
      : (window.location.href = `https://www.strava.com/oauth/authorize?client_id=130918&response_type=code&redirect_uri=${window.location.href}&approval_prompt=force&scope=read,activity:read`);
  }

  return (
    <div className="strava-connector-container">
      <div>
        <img
          src={require("assets/img/stravaLogo.png")}
          alt="Strava"
          width="100"
        />
      </div>
      <div className="strava-status">
        <h5 style={{ fontWeight: 600 }}>
          {isConnected
            ? "Aktualnie jesteś połączony"
            : "Aktualnie nie jesteś połączony"}
        </h5>
        <Button
          className="strava-button"
          onClick={() => toggleStravaConnection()}
        >
          {isConnected ? "Rozłącz" : "Połącz"}
        </Button>
      </div>
    </div>
  );
}

export default StravaConnector;
